import React from 'react';
import getDisplayName from 'react-display-name';
import hoistNonReactStatics from 'hoist-non-react-statics';

export const withInvalidAppState = (WrappedComponent, Fallback) => {
  class WithInvalidAppState extends React.Component {
    static displayName = `withInvalidAppState(${getDisplayName(WrappedComponent)})`;

    render() {
      if (this.props.workerError || !this.props.state) {
        if (this.props.wasWorkerErrorCaptured) {
          return <Fallback {...this.props} />;
        }

        const workerError =
          this.props.workerError || new Error('Worker initialization failed: There is no error, props were not set');

        throw workerError; // this error will be caught by sentry error boundary
      }
      return <WrappedComponent {...this.props} />;
    }
  }

  hoistNonReactStatics(WithInvalidAppState, WrappedComponent);

  return WithInvalidAppState;
};
